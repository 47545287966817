
              @import "@/assets/css/variables.scss";
            


























































.title {
  margin: 24px 0 16px;
  font-size: 14px;
  color: $text-secondary;
  line-height: 22px;
}

img {
  margin-bottom: 8px;
}

.info_content {

  /deep/ ul {
    li {
      font-size: 14px;
      color: $text-primary;
      line-height: 22px;
    }
  }
}

.alert_description {
  a {
    color: $warning;
  }
}
