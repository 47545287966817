
              @import "@/assets/css/variables.scss";
            


































































// @import '@/assets/css/pages/pendingAccounts.scss';
.pending_account {

  .el-alert--warning {
    margin-bottom: 16px;

    .el-alert__description {
      margin: 0;

      span {
        color: $text-link;
        cursor: pointer;
      }
    }
  }
}
