
              @import "@/assets/css/variables.scss";
            



















































































.el-alert {
  .el-alert__icon {
    align-self: flex-start;
  }

  &.is-light.el-alert--warning {
    border: 1px solid $warning;
    color: $warning;

    .el-alert__description {
      color: $info;
      line-height: 20px;
    }
  }

  &.is-light.el-alert--success {
    border: 1px solid $success;
    color: $success;

    .el-alert__description {
      color: $info;
      line-height: 20px;
    }
  }

  &.is-light.el-alert--error {
    border: 1px solid $danger;
    color: $danger;

    .el-alert__description {
      color: $info;
      line-height: 20px;
    }
  }

  &.is-light.el-alert--info {
    border: 1px solid $border-color-base;
    color: $info;

    .el-alert__description {
      color: $info;
      line-height: 20px;
    }
  }

  .el-alert__closebtn {
    @include rtl-sass-prop(right, left, 15px);
    @include rtl-sass-prop(left, right, unset);
  }
}
