
              @import "@/assets/css/variables.scss";
            





































































































































































































// @import '@/assets/css/pages/tradingList.scss';
.trading_list_dialog {
  .trading_list_table:nth-child(1) {
    margin-bottom: 24px;
  }

  .search_form {
    margin-bottom: 0;
  }

  .search_button {
    padding-top: 0;
  }
}
